<template>
  <div>
    <b-form-file
      multiple
      id="input-device-picture"
      accept="image/jpeg, image/png"
      :placeholder="$t('form.contract.edit.picture.placeholder')"
      v-model="pictures.claim_pictures"
    >
      <template slot="file-name" slot-scope="{ names }">
        <b-badge variant="dark">{{ names[0] }}</b-badge>
        <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
          + {{ names.length - 1 }} More files
        </b-badge>
      </template>
    </b-form-file>
  </div>
</template>

<script>
import format from "../../mixins/format";

export default {
  name: "components.report.report-image-upload",
  mixin: [format],
  props: {},
  computed: {},
  data: () => ({
    pictures: {
      claim_pictures: []
    }
  }),
  methods: {},
  watch: {
    pictures: {
      handler(n) {
        console.log(n);
        this.$emit("imageSelected", this.pictures);
      },
      deep: true
    }
  }
};
</script>
