<template>
  <div class="login">
    <div v-if="!uploaded">
      <b-col cols="12">
        <h2 class="typography-h2">
          <n-html :text="$t('support.request.upload.title')" />
        </h2>
        <div>
          <p class="typography-p-regular">
            <n-html :text="$t('support.request.upload.message')" />
          </p>
          <p v-if="uploadType == 'claim'">
            <report-image-upload
              @imageSelected="d => this.onClaimImageUpload(d)"
            />
          </p>
          <p v-else-if="uploadType == 'contract'">
            <insurance-image-upload
              @imageSelected="d => this.onInsuranceImageUpload(d)"
            />
          </p>
        </div>
      </b-col>
      <div>
        <b-col cols="12">
          <n-form-group
            id="button-group-claim-create"
            :invalid-feedback="$t('support.request.upload.error')"
            :state="form_states.form"
          >
            <n-button-regular
              class="float-right"
              @click.native="submit"
              :disabled="!valid || form_states.loading"
            >
              <n-html :text="$t('support.request.upload.submit')" />
            </n-button-regular>
          </n-form-group>
        </b-col>
      </div>
    </div>
    <div v-else>
      <h2 class="typography-h2">
        <n-html :text="$t('support.request.upload.success.title')" />
      </h2>
      <p class="typography-p-regular">
        <n-html :text="$t('support.request.upload.success.message')" />
      </p>
    </div>
  </div>
</template>

<script>
import InsuranceImageUpload from "../../components/insurances/insurance-image-upload.vue";
import ReportImageUpload from "../../components/report/report-image-upload.vue";

export default {
  name: "support.image",
  components: { InsuranceImageUpload, ReportImageUpload },
  data: () => ({
    form_states: {
      form: null,
      loading: false
    },
    form: {
      contract: {
        device_pictures: [],
        receipt_pictures: []
      },
      claim: []
    },
    uploaded: false
  }),
  computed: {
    uploadType() {
      return this.$route.query.type;
    },
    uploadId() {
      return this.$route.query.id;
    },
    valid() {
      return (
        (this.form.contract.device_pictures.length > 0 &&
          this.form.contract.receipt_pictures.length > 0) ||
        this.form.claim.length > 0
      );
    }
  },
  methods: {
    submit() {
      this.form_states.form = null;
      this.form_states.loading = true;

      const payload = {
        type: this.uploadType,
        id: atob(this.uploadId)
      };

      let formData = new FormData();
      if (this.uploadType == "claim") {
        console.log(this.form.claim);
        this.form.claim.forEach((f, i) => {
          formData.append(`file-claim-${i}`, f);
        });
      } else if (this.uploadType == "contract") {
        console.log(this.form.contract);
        this.form.contract.receipt_pictures.forEach((f, i) => {
          formData.append(`file-contract-${i}`, f);
        });
        this.form.contract.device_pictures.forEach((f, i) => {
          formData.append(`file-contract-${i}`, f);
        });
      }

      formData.append("data", JSON.stringify(payload));

      this.$store
        .dispatch("application/uploadImageForSupportRequest", {
          payload: formData
        })
        .then(() => {
          this.uploaded = true;
          this.form_states.loading = false;
        })
        .catch(() => {
          this.form_states.form = false;
          this.form_states.loading = false;
        });
    },
    async onClaimImageUpload(data) {
      console.log(data);
      if (data.claim_pictures.length > 0) {
        this.form.claim = data.claim_pictures;
      }
    },
    async onInsuranceImageUpload(data) {
      if (data.receipt_pictures.length > 0) {
        this.form.contract.receipt_pictures = data.receipt_pictures;
      }

      if (data.device_pictures.length > 0) {
        this.form.contract.device_pictures = data.device_pictures;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../theme/variables";

.icon {
  margin-right: 10px;
}
</style>
